import {lazy} from "react";

export const routes = [
    {
        path: '/',
        element: lazy(() => import('./Main')),
        with_menu: false
    },
    {
        path: '/search',
        element: lazy(() => import('./Search')),
        with_menu: true
    },
    {
        path: '/cat/:category_id',
        element: lazy(() => import('./Category')),
        with_menu: true
    },
    {
        path: '/cat/:category_id/art/:article_id/:article_translite',
        element: lazy(() => import('./Article')),
        with_menu: true
    },
    {
        path: '/help',
        element: lazy(() => import('./Help')),
        with_menu: true
    },
    {
        path: '/help/:question_id',
        element: lazy(() => import('./Help')),
        with_menu: true
    },
    {
        path: '/help/:question_id/:subquestion_id',
        element: lazy(() => import('./Help')),
        with_menu: true
    },
    {
        path: '/my_questions',
        element: lazy(() => import('./MyQuestions')),
        with_menu: true,
        private: true
    },
    {
        path: '/app/training',
        element: lazy(() => import('./Application/TrainingGrounds')),
        with_menu: true,
        private: true
    },
    {
        path: '/app/yandex_forms',
        element: lazy(() => import('./Application/YandexForms')),
        with_menu: true,
        private: true
    },
];
