import {memo} from "react";
import {useMenuParams} from "../../hooks/menuParams.hook";
import {MenuContext} from "../../context/MenuContext";
import Header from "./Header";
import Footer from "./Footer";
import MenuSidebar from "./MenuSidebar";
import GlobalSearch from "./Search";
import {useIsMobile} from "../../hooks/isMobile.hook";
import "./index.scss";


const Layout = ({withMenu, children}) => {
    const {
        loading,
        menuCategories,
        activeCategory,
        activeParentSubCategory,
        activeParentCategory,
        searchLetter,
        prevCategory
    } = useMenuParams();
    const isMobile = useIsMobile(1085);

    return (
        <MenuContext.Provider
            value={{
                loading,
                menuCategories,
                activeCategory,
                activeParentSubCategory,
                activeParentCategory,
                searchLetter,
                prevCategory
            }}
        >
            <div className="layout">
                <Header/>
                <main className="layout__content content">
                    <div className="layout__wrap">
                        {withMenu && !isMobile &&
                            <MenuSidebar/>
                        }
                        <div className="layout__wrap-left">
                            {withMenu &&
                                <GlobalSearch/>
                            }
                            {children}
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </MenuContext.Provider>
    );
};

export default memo(Layout);